<template>
  <div class="content-wrapper">
    <div class="content-lookup">
      <div class="lookup-left">
        <ul class="lookup-condition">
          <li class="field">
            <div class="title">사업장</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="search.bizCode"
                    :dataSource="businessPlaceDropdownListDatasource"
                    :allowFiltering="false"
                    :fields="{ text: 'text', value: 'value' }"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">사용자</div>
            <ul class="content">
              <li class="item">
                <ejs-dropdownlist
                    v-model="search.selectedUserDataSource"
                    :dataSource="search.userDatasource"
                    :allowFiltering="false"
                    :fields="{ text: 'text', value: 'value' }"
                    cssClass="lookup-condition-dropdown"
                />
              </li>
              <li class="item">
                <input-text
                    v-model="search.userNameOrId"
                    :disabled="!search.selectedUserDataSource"
                    placeholder="내용을 입력하세요."
                />
              </li>
            </ul>
          </li>
          <li class="field">
            <div class="title">로그인 일자</div>
            <ul class="content">
              <li class="item dateRange">
                <input-date-range
                    type="lookup-condition"
                    v-model="search.dateRange"
                />
              </li>
            </ul>
          </li>
        </ul>
        <div class="lookup-lookup">
          <erp-button
              button-div="GET"
              :is-shortcut-button="true"
              v-on:click.native="fetchUserLoginHist"
          >
            조회
          </erp-button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <report-view
          ref="reportViewComponent"
          :isPopup="true"
      />
      <article class="body-article">
        <!-- 아코디언 : accordion / 닫힘 : close -->
        <section class="article-section section-01">
          <div class="section-header">
            <div class="header-left">
              <div class="header-title">사용자 로그인 이력</div>
              <div class="header-caption">[{{ count }}건]</div>
            </div>
            <div class="header-right">
              <ul class="header-button">
                  <li class="print">
                  <erp-button
                      button-div="PRINT"
                      @click.native="onPrintClick"
                      :is-shortcut-button="true"
                  >인쇄
                  </erp-button>
                </li>
                <li class="print">
                  <erp-button
                      button-div="FILE"
                      @click.native="onExcelClick"
                  >
                    Excel
                  </erp-button>
                </li>
              </ul>
            </div>
          </div>
          <div class="section-body">
            <div class="body-grid">
              <ejs-grid-wrapper
                  ref="grid"
                  :allowEditing="false"
                  :allowExcelExport="true"
                  :allowPaging="true"
                  :allowResizing="true"
                  :provides="grid"
                  :pageSettings="pageSettings"
                  :columns="columns"
                  :data-source="content.userLoginHist"
                  @actionComplete="gridComplete"
              />
            </div>
          </div>
        </section>
      </article>
    </div>
  </div>

</template>


<script>
import moment from "moment";
import ReportView from "../../components/common/report/ReportView.vue";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import InputText from "../../components/common/text/InputText.vue";
import InputDateRange from "../../components/common/datetime/InputDateRange";
import commonMixin from "../../views/layout/mixin/commonMixin";
import { getBizCodesObject } from "@/utils/pageKeyUtil";
import GolfErpAPI from "../../api/v2/GolfErpAPI";
import {ExcelExport, ForeignKey, Page, Resize} from "@syncfusion/ej2-vue-grids";
import {numberWithCommas} from "@/utils/number";
import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "UserLoginHist",
  mixins: [commonMixin],
  props: {
  },
  components: {
    ReportView,
    ejsGridWrapper,
    InputDateRange,
    InputText,
    ErpButton,
  },
  created() {
  },
  mounted() {
  },
  computed: {
    businessPlaceDropdownListDatasource() {
      const businessPlaceDropdownListDatasource = (
        getBizCodesObject() || []
      ).map(({ bizName, bizCode }) => ({
        text: bizName,
        value: bizCode,
      }));
      businessPlaceDropdownListDatasource.unshift({ text: "ALL", value: "" });
      return businessPlaceDropdownListDatasource;
    },
  },
  data () {
    return{
      count: 0,
      isAddPrintPopupOpen: false,

      search: {
        bizCode: '',
        userNameOrId: '',
        selectedUserDataSource: 'userName',
        userDatasource: [
          {text: '사용자명', value: 'userName'},
          {text: '사용자ID', value: 'userId'}
        ],
        dateRange: {
          from: moment().startOf('month').format('YYYY-MM-DD'),
          to: moment().format('YYYY-MM-DD'),
        },
      },
      content: {
        userLoginHist: []
      },
      grid: [
        Resize,
        Page,
        ExcelExport,
        ForeignKey,
      ],
      columns: [
        {
          field: 'logId',
          visible: false,
          type: 'number',
        },
        {
          field: 'bizName',
          headerText: '사업장',
          textAlign: 'center',
          width: 150,
          type: 'string',
        },
        {
          field: 'userName',
          headerText: '사용자명',
          textAlign: 'center',
          width: 50,
          type: 'string',
        },
        {
          field: 'userId',
          headerText: '사용자 ID',
          textAlign: 'center',
          width: 50,
          type: 'number',
        },
        {
          field: 'email',
          headerText: '사용자 이메일',
          textAlign: 'center',
          width: 100,
          type: 'string',
        },
        {
          field: 'loginDt',
          headerText: '로그인 일시',
          textAlign: 'center',
          width: 75,
          type: 'string',
        },
        {
          field: 'opersysm',
          headerText: '운영체제',
          textAlign: 'center',
          width: 75,
          type: 'string',
        },
        {
          field: 'brwsr',
          headerText: '브라우저',
          textAlign: 'center',
          width: 75,
          type: 'string',
        },
        {
          field: 'connectIp',
          headerText: '접속 IP',
          textAlign: 'center',
          width: 75,
          type: 'string',
        },
      ],
      pageSettings: {pageSize: 50},
    };
  },
  methods: {
    async fetchUserLoginHist() {
      let params = {
        bizCode: this.search.bizCode,
        fromDate: this.search.dateRange.from,
        toDate: this.search.dateRange.to,
        selectedUserDataSource: this.search.selectedUserDataSource,
        userNameOrId: this.search.userNameOrId,
      };

      await GolfErpAPI.getUserLoginHist(params).then(res => {

        if (res) {
          this.content.userLoginHist = res;
        }

      });
    },

    onPrintClick() {
      const result = this.$refs.grid.getGridBatchData();

      if (result.length < 1) {
        return this.errorToast(this.$t('report.popupMessage.noData'));
      }

      const searchOptionsList = [
        {
          key: '사업장',
          value: this.search.bizCode
        },
        {
          key : '사용자',
          value: this.search.userNameOrId
        },
        {
          key: '조회일자',
          value: `${this.search.dateRange.from} ~ ${this.search.dateRange.to}`
        }
      ];

      const searchOptions = searchOptionsList
        .filter(item => !!item.value)
        .map(item => item.key + ': ' + item.value)
        .join(', ');

      const reportData = {
        reportJson: {
          jsonData: result,
          username: this.search.userName,
          uniqueCode: this.$options.name,
          searchOptions,
        },
      };

      this.$refs.reportViewComponent.postReport(reportData, this.$options.name);
    },
    gridComplete() {
      this.count = numberWithCommas(
        this.$refs.grid?.getGridBatchCount() || 0
      );
    },

    onExcelClick() {
      this.$refs.grid.excelExport();
    },
  }
};
</script>

<style scoped>

</style>
